@font-face {
  font-family: "Muli";
  src: url("./static/fonts/Muli.ttf") format("truetype");
}
@font-face {
  font-family: "MuliBold";
  src: url("./static/fonts/Muli-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MuliLight";
  src: url("./static/fonts/Muli-Light.ttf") format("truetype");
}
@font-face {
  font-family: "MuliSemiBold";
  src: url("./static/fonts/Muli-SemiBold.ttf") format("truetype");
}

html {
	font-size: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Muli', Helvetica, Arial, sans-serif, -apple-system, BlinkMacSystemFont;
	font-size: 1rem;
	line-height: 1.5;
	background-color: #EDF1F5;
}

button:disabled {
	opacity: 0.5;
}

#web-messenger-container {
  bottom: 0 !important;
}